import classNames from 'classnames'
import React from 'react'
import scs from '../cards/card/card.scss'
import ls from '../list-layout/list/list.scss'
import ss from '../single-event/single-event.scss'
import ts from '../single-event/title/title.scss'
import s from './empty-state.scss'
import {EmptyStateProps} from './interfaces'

export const EmptyState = ({singleEventWidget, listLayout, t}: EmptyStateProps) => {
  return (
    <div
      className={classNames(s.container, {
        [ss.fullLayout]: singleEventWidget,
        [ls.listContainer]: !singleEventWidget && listLayout,
        [scs.container]: !singleEventWidget && !listLayout,
        [ts.root]: singleEventWidget,
        [s.listLayout]: !singleEventWidget,
      })}
    >
      {t('widgetEmptyStateNotification')}
    </div>
  )
}
